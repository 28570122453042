<template>
   <div>
    <p class="detailTitle p28">产品点差</p>
  <div class="speed">
    <!-- 产品点差 -->
    <div class="left" >
       <iframe
        style="height: 100%; width: 100%"
        src="https://assets.app.lmax.com/resprime2/resprime2.html?autow=1&ld4=1&wkdpage=WkdFX&depth=0&resmode=light&show=12&bodycss=sg+cm+nbt+nbb+bs+np+decimal&wkspecial=1&spdp=1"
        frameborder="0"
      ></iframe> 

    </div>
    <div class="right">
      <div class="bottom">
        <p class="text">
          客户可通过LMAX
          GLOBAL直接进入可靠的外汇交易平台，对接交易所限价单报价的市场深度，并提供透明的报价和具竞争力的点差。
        </p>
        <div>
          <img src="../../assets/img/detail/专业 专业课@2x.png" alt="" />
          <p>来自顶级金融机构以限价单方式提供的低点差稳定实价流动性</p>
        </div>
        <div>
          <img src="../../assets/img/detail/mode.png" alt="" />
          <p>严格按照价格/时间优先的模式撮合</p>
        </div>
        <div>
          <img src="../../assets/img/detail/真实可信@2x.png" alt="" />
          <p>实时和完整的市场深度数据和交易数据</p>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valList: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.speed {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .left {
    width: 481px;
    height: 356px;
  }
  .right {
    color: #000000;
    width: 447px;
    .title {
      // font-weight: 00;
    }

    .bottom {
      background: url("../../assets/img/detail/矩形 2@2x (1).png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 20px 30px;
      color: #000000;
      font-size: 18px;
      margin-top: 40px;
      img {
        height: 25px;
        margin-right: 6px;
      }
      .text {
        margin-bottom: 40px;
        line-height: 25px;
      }
      div {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        p {
          line-height: 25px;

          font-weight: 600;
        }
      }
    }
  }
}
</style>
<template>
  <div class="contact">
    <div class="banner">
      
      <img src="../../assets/img/contact/banner.png" alt="" />
    </div>
    <div class="container">
      <div class="WebMain">
        <div class="list">
          <div
            v-for="item in list"
            :key="item.id"
            @click="changeActiveId(item.id)"
          >
            <p :class="item.id === activeId ? 'active p18' : 'p18'">
              <span>{{ item.title }}</span>
              <i class="el-icon-d-arrow-right"></i>
            </p>
            <div
              v-if="showContent  && item.id === activeId && customStyle"
              class="phoneContent"
            >
            <AccountTypeVue v-if="activeId === 8 || activeId === 23"></AccountTypeVue>
            <TradingPlatformVue v-if="activeId === 10"></TradingPlatformVue>
            <Spread v-if="activeId === 11"></Spread>
            <Speed v-if="activeId === 12"></Speed>
            <AppDownload v-if="activeId === 33"> </AppDownload>
            <CashOut  v-if="activeId === 21"></CashOut>
            <!-- <IframePage v-if="activeId === 34"></IframePage> -->
           </div>
            <div
              v-if="showContent && item.id === activeId && !customStyle"
              class="phoneContent"
              v-html="article.content"
            ></div>
          </div>

          <!-- <-->
        </div>
        
        <div class="content" v-if="customStyle">
          <AccountTypeVue v-if="activeId === 8 || activeId === 23"></AccountTypeVue>
          <TradingPlatformVue v-if="activeId === 10"></TradingPlatformVue>
          <Spread v-if="activeId === 11"></Spread>
          <Speed v-if="activeId === 12"></Speed>
          <AppDownload v-if="activeId === 33"> </AppDownload>
          <CashOut  v-if="activeId === 21"></CashOut>
          <!-- <IframePage v-if="activeId === 34"></IframePage> -->
        </div>
        <div class="content" v-else v-html="article.content"></div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { articleDetailAPI, articleListAPI } from "@/api/article/article";
import AccountTypeVue from '@/components/detail/AccountType.vue';
import TradingPlatformVue from '@/components/detail/TradingPlatform.vue';
import Speed from "@/components/detail/Speed.vue";
import Spread from "@/components/detail/Spread.vue";
import AppDownload from "@/components/detail/AppDownload.vue";
import CashOut from "@/components/detail/CashOut.vue";
import IframePage from "../../components/IframePage.vue";
export default {
  components: {AccountTypeVue, TradingPlatformVue, Speed, Spread, AppDownload, CashOut,IframePage},
  data() {
    return {
      showContent: false,
      list: [],
      article: "",
      activeId: 1,
      categoryId: 2, //文章分类id　
      customStyle: false,
    };
  },
  methods: {
    // 获取文章列表
    async getArticleList() {
      this.categoryId = Number(this.$route.params.id);
      let info = {
        pageNum: 1,
        pageSize: 100,
        categoryId: this.categoryId,
      };
      const res = await articleListAPI(info);
      if (res.code === 200) {
        this.list = res.data.list;
        if (this.list.length > 0) {
          if(this.$route.query.id) {
            this.customStyle = true;
            this.activeId = Number(this.$route.query.id)
          } else {
            this.activeId = this.list[0].id;
          }
          
          this.getDetail();
        }
      } else {
        this.$message({
          type: "info",
          showClose: true,
          message: "列表获取失败!",
        });
      }
    },
    // 获取文章详情
    async getDetail(val, val2) {
      const res = await articleDetailAPI({ id: this.activeId });

      if (res.code === 200) {
        this.article = res.data;
        if (val && val2) {
          if (this.showContent === true && val === val2) {
            this.showContent = false;
          } else {
            this.showContent = true;
          }
        }

        let metaInfo = {
          title: this.article.metaTitle,
          keywords: this.article.metaKeywords,
          description: this.article.metaDescription,
        };
        this.$store.commit("CHANGE_META_INFO", metaInfo);
      } else {
        this.$message({
          type: "info",
          showClose: true,
          message: "详情获取失败!",
        });
      }
    },
    changeActiveId(val) {
      let val2 = this.activeId;
      this.activeId = val;
      if(val === 8 || val === 10 || val === 11 || val === 12 || val === 21|| val === 23 || val === 33 ) {
         this.customStyle = true;
        if(this.showContent === true && val === val2){
          this.showContent = false;
        } else {
          this.showContent = true;
        }
        
      } else {
        this.customStyle = false;
        this.getDetail(val, val2);
      }
      

    },
  },
  created() {
    this.getArticleList();
  },
  watch: {
    $route(to, from) {
      this.getArticleList();
    },
  },
};
</script>
  
<style lang="scss" scoped>
.contact {
  .banner {
    position: relative;
    img {
      width: 100%;
      height: 95px;
    }
  }

  .container {
    margin: 50px 0px;

    .WebMain {
      display: flex;
    }

    .list {
      min-height: 600px;
      width: 250px;
      box-sizing: border-box;
      background: #FFFFFF;
      box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.1);
      border-radius: 6px;
      padding-left: 20px;

      p {
        width: 100%;
        height: 50px;
        line-height: 50px;
        margin-bottom: 10px;
        padding-right: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        i {
          color: transparent;
          line-height: 50px;
        }
        span {
          color: #595959;
        }
      }

      .active {
        background: linear-gradient(270deg, #e9f7ff 0%, #ffffff 100%);
      
        i {
          text-align: right;
          color: var(--custom-color);
        }

        span {
          color: var(--second-color);
        }
      }
    }
    .phoneContent {
      display: none;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      padding: 20px 0;
      margin-bottom: 20px;
     
      ::v-deep p {
        line-height: 30px;
        margin-top: 20px;
        a{
          color: #3FA3FF;
        }
        img {
          max-width: 100% !important;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }

    .content {
      width: 100%;
      box-sizing: border-box;
      margin-left: 30px;
      background: #FFFFFF;
      box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.1);
      border-radius: 6px;
      padding-left: 20px;
      // padding-top: 30px;
      padding-bottom: 25px;
      padding-right: 20px;
      
      ::v-deep .p18{
        font-size: 17px !important;
        
      }
      ::v-deep p {
        line-height: 30px;
        a{
          color: #3FA3FF;
        }
        img {
          max-width: 100% !important;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
      
    }
  }
}

@media screen and (max-width: 750px) {
  .contact .container {
    .phoneContent {
      display: block;
    }
    .WebMain {
      display: block;
    }
    .content {
      display: none;
    }

    .list {
      width: 100%;
      border: 0;
      padding-right: 0;
    }
  }
}
</style>
<template>
  <div class="detail">
    <p class="detailTitle p28">网页账户，MT4账户</p>
    <p class="p18 text">
      在LMAX开户做交易，您可以选择网页账户或MT4账户进行交易，账户开通后，您可以选择直接在网页账户交易，也可以选择在MT4账户交易，或同时使用网页账户和MT4账户进行交易。
    </p>
    <p class="p18 text">
      网页账户为LMAX自己的交易账户，所有在LMAX开户的客户都有网页账户，出入金也需要通过网页账户进行操作。
    </p>
    <p class="p18 text">
      MT4是独立的第三方交易平台，为适应客户使用习惯，专门提供，与网页平台的资金和仓位是分开的，资金不通用。
      关于网页账户和MT4账户区别如下：
    </p>
    <div class="accountList">
      <div class="item" v-for="item in accountList" :key="item.id">
        <div class="top">
          <img
            class="logo"
            :src="require(`../../assets/img/detail/${item.logo}`)"
            alt=""
          />
          <p class="p20">{{ item.name }}</p>
          <span class="span14">下载地址&nbsp;&nbsp;&nbsp;|</span>
          <span></span>
          <img class="icon" @click="toDetail" src="../../assets/img/detail/icon (1).png" alt="" />
          <img class="icon" @click="toDetail" src="../../assets/img/detail/icon (2).png" alt="" />
          <img class="icon" @click="toDetail" src="../../assets/img/detail/icon (3).png" alt="" />
        </div>
        <div class="bottom">
          <div class="item">
            <p class="label">图标使用:</p>
            <p>{{ item.val1 }}</p>
          </div>
          <div class="item">
            <p class="label">程序使用:</p>
            <p>{{ item.val2 }}</p>
          </div>
          <div class="item">
            <p class="label">合约量:</p>
            <p>{{ item.val3 }}</p>
          </div>
          <div class="item">
            <p class="label">最小合约数:</p>
            <p>
              <span>
                {{ item.val4 }}
              </span>
              <span>
                {{ item.val5 }}
              </span>
              <span>
                {{ item.val6 }}
              </span>
            </p>
          </div>
          <div class="item">
            <p class="label">手续费收取:</p>
            <p>{{ item.val7 }}</p>
          </div>
          <div class="item">
            <p class="label">手续费显示:</p>
            <p>{{ item.val8 }}</p>
          </div>
          <div class="item">
            <p class="label">锁单功能:</p>
            <p>{{ item.val9 }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accountList: [
        {
          id: 1,
          name: "网页账户",
          logo: "logo (1).png",
          link1: "",
          link2: "",
          link3: "",
          val1: "全以英文显示，不切合国内客户的使用习惯，使用不方便",
          val2: "支持API（.net、java）、FIX使用",
          val3: "1个外汇合约 = 10,000个货币单位",
          val4: "外汇：0.1个合约",
          val5: "黄金白银原油：0.1个合约",
          val6: "指数CFD：1个合约",
          val7: "按照交易面值单向计算：开仓收一次，平仓再收一次。",
          val8: "在开仓或平仓时实时反映在账户余额上；隔夜利息在持单过夜时实时反映在账户余额上。",
          val9: " 不支持",
        },
        {
          id: 2,
          name: "MT4账户",
          logo: "logo (2).png",
          link1: "",
          link2: "",
          link3: "",
          val1: "图表功能比较全面，使用比较方便",
          val2: "支持EA交易",
          val3: "1手外汇 = 100,000个货币单位",
          val4: "外汇：0.01个合约",
          val5: "黄金白银原油：0.01个合约",
          val6: "指数CFD：1个合约",
          val7: "固定双向计算：一开一合在开仓时一次性收取",
          val8: "手续费在开仓时直接反映在账户终端上；隔夜利息在持单 过夜时直接反映在账户终端上。手续费和隔夜利息在平仓后反映在账户余额上。",
          val9: " 支持（注意：如果持对冲的仓位过夜，多仓和 空仓都会各别支付/收取隔夜利息。锁单不占用MT4平台的 保证金，但是，平仓时都要转为市价单直接发到市场成 交。在真实的市场上，任何订单都会占用保证金，以确保成交。）",
        },
      ],
    };
  },
  methods: {
    // 跳转到下载页面
    toDetail() {
      this.$router.push({
        path: '/detail/6',
        query: {
          id: 33
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.detail {
  .text {
    line-height: 26px;
    color: #444444;
    margin: 20px 0px 30px 0px;
  }
  .accountList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 475px;
      color: #5f5f5f;
      .top {
        display: flex;
        align-items: center;
        color: white;
        // height: 64px;
        padding: 25px 25px;
        background: linear-gradient(90deg, #3d67a0 0%, #293e64 100%);
        img {
          height: 20px;
          // margin-left: 25px;
        }
        .icon {
          margin-left: 25px;
        }
        .logo {
          height: 28px;
          width: 28px;
        }
        p {
          margin-left: 14px;
          margin-right: 80px;
          margin-top: 0px  !important;
        }
      }
      .bottom {
        padding: 20px 20px;
        // max-height: 500px;
        // overflow-y: scroll;
        background: #f9fbff;
        .item {
          display: flex;
          margin-bottom: 30px;
          width: 100%;
          box-sizing: border-box;

          p {
            font-size: 16px;
            color: #5f5f5f;
            line-height: 20px;
            text-align: left;
            width: calc(100% - 120px);
            span {
              display: block;
            }
          }
          .label {
            width: 120px;
          }
        }
      }
    }
  }
}
</style>
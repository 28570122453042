<template>
  <div class="detail">
    <p class="detailTitle p28">入金说明</p>
    <p class="p18 text">
      <!-- 交易产品超过100种，涵盖了外汇、贵金属、股指、和现货商品。
      交易所模式让所有交易商和专业交易者都有同等机会，可以进入LMAX交易所的中央市场深度,
      享受顶级银行和机构以限价单方式提供的低点差、稳定的实价流动性。 -->
    </p>
    <div class="accountList">
      <div class="item" v-for="item in accountList" :key="item.id">
        <div class="name">
          <p class="p26">{{ item.name }}</p>
        </div>
        <div class="bottom">
          <div class="val2">
            <p class="p32 title1">{{ item.val1 }}</p>
            <!-- <p class="p16 title2">监管</p> -->
          </div>
          <div class="val3">
            <p class="p18 title1">{{ item.val2 }}</p>
          </div>
          <div class="val4">
            <p class="p18 title1">{{ item.val3 }}</p>
          </div>
          <div class="val5">
            <p class="p18 title1">{{ item.val4 }}</p>
          </div>
          <div class="val6" v-if="item.val6">
            <p class="p16 title2">
              <span class="p18">入金：</span>{{ item.val5 }}
            </p>
            <p class="p16 title2">
              <span class="p18">出金：</span>{{ item.val6 }}
            </p>
          </div>
          <div class="val6" v-else>
            <p class="p16 title2">{{ item.val5 }}</p>
          </div>
          <div class="val7">
            <p class="p18 title1">{{ item.val7 }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accountList: [
        {
          id: 1,
          name: "电汇",
          val1: "UK/NZ",
          val2: "25-50美金",
          val3: "实时汇率",
          val4: "3-5工作日",
          val5: "入金：通过银行操作境外汇款到指定托管银行账户。",
          val6: "通过LMAX交易系统填写出金信息并进行出金操作。",
          val7: "不推荐，内地账户目前成功率极低，如果有境外账户可以推荐。",
        },
        {
          id: 2,
          name: "信用卡",
          val1: "UK",
          val2: "不定",
          val3: "实时汇率",
          val4: "一般实时",
          val5: "出入金均通过LMAX交易系统进行操作。",
          val6: null,
          val7: "不推荐，内地账户目前成功率极低，如果有境外账户可以推荐",
        },

        {
          id: 3,
          name: "银联",
          val1: "UK/NZ",
          val2: "入金2%，出金0.5%",
          val3: "由支付公司决定，参考当天现钞买入价",
          val4: "1-3工作日",
          val5: "通过LMAX交易系统或个人管理系统右上角“客户端口”-“出入金”下拉最下方，找到在线银联入金端口。按照指南操作，操作结束后将汇款凭证上传或发邮件给LMAX进行说明。汇款凭证上需同时出现：银行LOGO，您的全名，银行卡号，汇款金额。",
          val6: "发邮件到info@lmax.com，并写明出金金额，出金账户，收款银行信息，由LMAX进行操作。",
          val7: "推荐。目前银联出入金通道比较稳定，成本较低，出入金流程简单，处理时间较短。",
        },
        {
          id: 4,
          name: "数字货币USDT/C",
          val1: "MU",
          val2: "入金1%，出金免费",
          val3: "当时 USDT/USDC 的实时价格",
          val4: "1个工作日",
          val5: "登陆LMAX专用数字货币出入金账户，右上角“客户端口”-“出入金”下拉找到USDT/C的电子钱包，按照指南操作。",
          val6: "发送邮件到info@lmax.com，写明出金金额，出金账户，收款银行信息，并附上银行网银截图（需包括您的姓名，银行名称和LOGO，银行账号）。",
          val7: "推荐。目前毛里求斯账户仅支持数字货币出入金方式，目前通道稳定，成本较低，流程简单，处理时间极短。",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.detail {
  p{
    margin-top: 0 !important;
  }
  .text {
    line-height: 26px;
    color: #444444;
    margin: 20px 0px 30px 0px;
  }
  .accountList {
    display: flex;
    // flex-wrap: wrap;
    // justify-content: center;
    .item {
      width: 220px;
      margin-right: 27px;
      border-radius: 10px;
      overflow: hidden;
      background: #f5f5f5;
      text-align: center;
      box-sizing: border-box;
      &:first-child {
        .name {
          background: #95a7d7;
        }
      }
      &:nth-child(2) {
        background: #ffffff;
        .name {
          background: #719397;
        }
      }
      &:nth-child(3) {
        .name {
          background: #4c7cd4;
        }
      }
      &:last-child {
        margin-right: 0;
        p{
          // font-size: 20px !important;
        }
        background: #ffffff;
        .name {
          background: #a0ca8d;
        }
      }

      .bottom {
        padding: 0 20px;
        div {
          border-bottom: 1px solid #cacaca;
          box-sizing: border-box;
        }
        p {
          span {
            color: #3a3a3a;
          }
        }
      }
      .title1 {
        color: #3a3a3a;
        // line-height: 25px;
      }
      .title2 {
        color: #999999;
        // line-height: 25px;
      }
      .name {
        color: white;
        // padding: 44px 32px;
        
height: 115px;
display: flex;
align-items: center;
justify-content: center;
      }
      .val2 {
        padding-top: 52px;
        padding-bottom: 21px;
      }
      .val3,
      .val4,
      .val5,
      .val6,
      .val7 {
        padding: 25px 0;
      }
      .val9,
      .val8,
      .val10 {
        padding: 30px 0;
      }
      .val7 {
        border-bottom: 0 !important;
      }
      .val6{
        min-height: 150px;
      }
      .val6,
      .val7 {
        p {
          text-align: left;
        }
      }
      .val4{
        min-height: 111px;
      }
      .val6{
        min-height: 233px;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .detail .accountList .item {
    margin-right: 0px;
  }
}
</style>
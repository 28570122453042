<template>
  <div class="download">
    <!-- 软件下载 -->
    <p class="detailTitle p28">软件下载</p>
    <div class="content">
      <div class="item" v-for="item in list" :key="item.id">
        <div class="top">
          <div class="left">
            <img
              :src="require(`../../assets/img/detail/${item.icon}`)"
              alt=""
            />
            <div>
              <p class="name">{{ item.name }}</p>
              <p class="rule">{{ item.rule }}</p>
            </div>
          </div>
          <p class="title">{{ item.title }}</p>
        </div>
        <div class="center">
          <div class="icon" v-if="item.link1 !== ''">
            <img src="../../assets/img/detail/web@2x.png" alt="" />
            <span>Web Trader</span>
            <img
              class="download"
              src="../../assets/img/detail/下载@2x.png"
              alt=""
            />
          </div>
          <div class="icon" v-if="item.link2 !== ''">
            <img src="../../assets/img/detail/windows@2x.png" alt="" />
            <span>Windows OS</span>
            <img
              class="download"
              src="../../assets/img/detail/下载@2x.png"
              alt=""
            />
          </div>
          <div class="icon" v-if="item.link3 !== ''">
            <img src="../../assets/img/detail/mac-os@2x.png" alt="" />
            <span>MAC OS</span>
            <img
              class="download"
              src="../../assets/img/detail/下载@2x.png"
              alt=""
            />
          </div>
          <div class="icon" v-if="item.link4 !== ''">
            <img src="../../assets/img/detail/IOS OS@2x.png" alt="" />
            <span>IOS OS</span>
            <a :href="item.link4" target="_blank"> <img
              class="download"
              src="../../assets/img/detail/下载@2x.png"
              alt=""
            /></a>
           
          </div>
          <div class="icon" v-if="item.link5 !== ''">
            <img src="../../assets/img/detail/安卓 (1)@2x.png" alt="" />
            <span>Android OS</span>
            <img
              class="download"
              src="../../assets/img/detail/下载@2x.png"
              alt=""
            />
          </div>
        </div>
        <!-- <div class="bottom">
          <p>{{ item.notes }}</p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          id: 1,
          icon: "icon (4).png",
          title: "WANHAITOUZI",
          name: "英国账户",
          rule: "软件使用介绍、使用规则",
          link1: "1",
          link2: "",
          link3: "",
          link4: "1",
          link5: "1",
          notes:
            "注意事项-注意事项-注意事项-注意事项-注意事项-注意事项-注意事项-注意事项-注意事项",
        },
        {
          id: 2,
          icon: "icon (5).png",
          title: "WANHAITOUZI",
          name: "MT4 — 1",
          rule: "软件使用介绍、使用规则",
          link1: "",
          link2: "1",
          link3: "1",
          link4: "http://www.maxiveinvest.com/metatrader4.apk",
          link5: "1",
          notes:
            "注意事项-注意事项-注意事项-注意事项-注意事项-注意事项-注意事项-注意事项-注意事项",
        },
        {
          id: 3,
          icon: "icon (5).png",
          title: "WANHAITOUZI",
          name: "MT4 — 2",
          rule: "软件使用介绍、使用规则",
          link1: "",
          link2: "1",
          link3: "1",
          link4: "http://www.maxiveinvest.com/metatrader4.apk",
          link5: "1",
          notes:
            "注意事项-注意事项-注意事项-注意事项-注意事项-注意事项-注意事项-注意事项-注意事项",
        },
        {
          id: 4,
          icon: "icon (5).png",
          title: "WANHAITOUZI",
          name: "MT4 — 3",
          rule: "软件使用介绍、使用规则",
          link1: "",
          link2: "1",
          link3: "1",
          link4: "http://www.maxiveinvest.com/metatrader4.apk",
          link5: "1",
          notes:
            "注意事项-注意事项-注意事项-注意事项-注意事项-注意事项-注意事项-注意事项-注意事项",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@keyframes boxShadow {
  from {
    // box-shadow: 6px 6px 8px 0px #82bfe4;
  }
  to {
    
box-shadow: 0px 4px 12px 0px rgba(175,188,197,0.5);
  }
}
.download {
  .content {
    .item {
      margin-top: 30px;
      background: #fafdff;
      padding: 20px 30px;
      border-radius: 13px;
      &:hover {
        animation-name: boxShadow;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        // animation-iteration-count: infinite;
      }
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        //   background: linear-gradient(135deg, rgba(241,243,244,0.41) 0%, #DFE3E6 100%);
        .left {
          display: flex;

          img {
            width: 58px;
            margin-right: 20px;
          }
          p{
            margin-top: 0px !important;
          }
          .name {
            font-size: 18px;
            font-weight: 600;
            color: #41484d;
          }
          .rule {
            font-size: 14px;
            color: #6e7173;
          }
        }
        .title {
          font-size: 28px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #82bfe5;
          line-height: 32px;
          background: linear-gradient(
            90deg,
            rgba(184, 225, 243, 0.14) 0%,
            #82bfe4 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .center {
        display: flex;
        // margin-bottom: 34px;
        .icon {
          overflow: hidden;
          width: 200px;
          height: 60px;
          display: flex;
          align-items: center;
          border: 1px solid;
          margin-left: 24px;
          border-radius: 8px;
          border-image: linear-gradient(
              90deg,
              rgba(254, 255, 255, 0.02),
              rgba(210, 228, 238, 1)
            )
            1 1;
          span {
            font-size: 13px;
            color: var(--custom-color);
            display: inline-block;
            line-height: 18px;
            margin-left: 14px;
            margin-right: 38px;
          }
          img {
            max-width: 22px;
          }
          .download {
            height: 15px;
            width: 14px;
            cursor: pointer;
          }
        }
      }
      .bottom {
        line-height: 12px;
        p {
          color: #939799;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
<template>
  <div>
    <p class="detailTitle p28">执行速度</p>
  <div class="speed">
    <!-- 执行速度 -->
    <div class="left">
      <iframe
        style="height: 100%; width: 100%"
        src="https://s3-eu-west-1.amazonaws.com/lmax-static/website-performance.html"
        frameborder="0"
      ></iframe>
    </div>
    <div class="right">
      <p class="title p18">选择我们的7大理由</p>
      <div class="bottom">
        <div class="item" v-for="item in valList" :key="item">
          <span></span>
          <span>{{ item }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      valList: [
        "流畅，实时，完整订单的市场数据",
        "无“最后观望”的限价单流动性",
        "通过FIX (4.2 / 4.4) 获取完整市场数据",
        "平均订单成交时间少于4毫秒",
        "通过分配管理模型、高速的信息传输效能，交易所可达到庞大的订单吞吐量",
        "系统内各阶段的高速回复能力，随时都可以接受和处理所有订单",
        "高扩展性的架构，可应付与日俱增的交易需求",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.speed {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .left {
    width: 522px;
    height: 424px;
  }
  .right {
    color: #000000;
    width: 447px;
    .title {
      font-weight: 600;
    }

    .bottom {
      margin-top: 16px;
      // height: 354px;
      // height: 100%;
      background: url("../../assets/img/detail/矩形 2@2x.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 20px 30px;

      color: #000000;
      // box-sizing: border-box;
      .item {
        margin-bottom: 18px;
        &:last-child{
          margin-bottom: 0;
        }
        span {
          font-size: 18px;
          &:first-child {
            display: inline-block;
            width: 13px;
            height: 13px;
            background: var(--custom-color);
            border-radius: 50%;
            margin-right: 14px;
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="detail">
    <p class="detailTitle p28">账户类型</p>
    <p class="p18 text">
      交易产品超过100种，涵盖了外汇、贵金属、股指、和现货商品。
      交易所模式让所有交易商和专业交易者都有同等机会，可以进入LMAX交易所的中央市场深度,
      享受顶级银行和机构以限价单方式提供的低点差、稳定的实价流动性。
    </p>
    <div class="accountList">
      <div class="item" v-for="item in accountList" :key="item.id">
        <div class="name">
          <p class="p26">{{ item.name }}</p>
        </div>
        <div class="bottom">
          <div class="val2">
            <p class="p32 title1">{{ item.val1 }}</p>
            <p class="p16 title2">监管</p>
          </div>
          <div class="val3">
            <p class="p18 title1">{{ item.val2 }}</p>
          </div>
          <div class="val4">
            <p class="p26 title1">{{ item.val3 }}</p>
            <p class="p16 title2">{{ item.val4 }}</p>
          </div>
          <div class="val5">
            <p class="p18 title1">浮动</p>
          </div>
          <div class="val6">
            <p class="p26 title1">{{ item.val5 }}</p>
            <p class="p16 title2">{{ item.val6 }}</p>
          </div>
          <div class="val7">
            <p class="p26 title1">{{ item.val7 }}</p>
            <p class="p16 title2">{{ item.val8 }}</p>
          </div>
          <div class="val8">
            <p class="p18 title1">{{ item.val9 }}</p>
          </div>
          <div class="val9">
            <p class="p18 title1">{{ item.val10 }}</p>
            <p class="p18 title">{{ item.val11 }}</p>
          </div>
          <div class="val10">
            <a :class="item.val12 === null ? 'none': ''" :href="item.val12" :aria-disabled="item.val12 === null ? true : false" target="_blank">账户申请</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accountList: [
        {
          id: 1,
          name: "英国零售账户",
          val1: "FCA",
          val2: "交易所模式",
          val3: "1:30",
          val4: "最大杠杆",
          val5: "$1000",
          val6: "最低入会",
          val7: "0.01",
          val8: "最低手数",
          val9: "银行根据libor利息计算和收取",
          val10: "传统零售账户",
          val11: "FCA全面监管",
          val12: "https://www.lmax.com/global/apply-for-an-account?affid=YINGA",
        },
        {
          id: 2,
          name: "英国专业账户",
          val1: "FCA",
          val2: "交易所模式",
          val3: "1:100",
          val4: "最大杠杆",
          val5: "$1000",
          val6: "最低入会",
          val7: "0.01",
          val8: "最低手数",
          val9: "银行根据libor利息计算和收取",
          val10: "针对专业交易客户",
          val11: "FCA全面监管",
          val12: null,
        },

        {
          id: 3,
          name: "新西兰账户",
          val1: "FSP",
          val2: "交易所模式",
          val3: "1:200",
          val4: "最大杠杆",
          val5: "$1000",
          val6: "最低入会",
          val7: "0.01",
          val8: "最低手数",
          val9: "银行根据libor利息计算和收取",
          val10: "高杠杆",
          val11: "开通便捷",
          val12: "https://www.lmax.com/global/apply-for-an-account?affid=YingyingduNZ",
        },
        {
          id: 4,
          name: "毛里求斯账户",
          val1: "FSC",
          val2: '',
          val3: '',
          val4: "",
          val5: "",
          val6: "",
          val7: "",
          val8: "",
          val9: "银行根据libor利息计算和收取",
          val10: "可交易数字货币",
          val11: "",
          val12: "https://mu.lmax.com/global/apply-for-an-account?affid=YINGAMARB",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.detail {
  p{
    margin-top: 0 !important;
  }
  .text {
    line-height: 26px;
    color: #444444;
    margin: 20px 0px 30px 0px;
  }
  .accountList {
    display: flex;
    .item {
      width: 220px;
      margin-right: 27px;
      border-radius: 10px;
      overflow: hidden;
      background: #f5f5f5;
      text-align: center;
      box-sizing: border-box;
      &:first-child {
        .name {
          background: #95a7d7;
        }
      }
      &:nth-child(2) {
        
background: #FFFFFF;
        .name {
          background: #719397;
        }
      }
      &:nth-child(3) {
        .name {
          background: #4c7cd4;
        }
      }
      &:last-child {
        margin-right: 0;
background: #FFFFFF;
        .name {
          background: #a0ca8d;
        }
      }
      
      .bottom {
        padding: 0 20px;
        div {
          border-bottom: 1px solid #cacaca;
          // min-height: 100px;
          box-sizing: border-box;
        }
      }
      .title1 {
        color: #3a3a3a;
        // line-height: 25px;
      }
      .title2 {
        color: #999999;
        // line-height: 25px;
      }
      .name {
        color: white;
        padding: 44px 32px;
      }
      .val2 {
        padding-top: 52px;
        padding-bottom: 21px;
      }
      .val3,
      .val4,
      .val5,
      .val6,
      .val7 {
        padding: 25px 0;
      }
      .val9,
      .val8,
      .val10 {
        padding: 30px 0;
      }
      .val10 {
        border-bottom: 0 !important;
        a{
            color: #3fa3ff;
        }
        .none{
          
color: #C4C8CC;
        }
      }
      .val3{
        min-height: 81px;
      }
      .val4, .val6, .val7{
        min-height: 131px;
      }
      .val9{
        min-height: 141px;
      }
    }
  }
}
@media screen and (max-width: 750px) {
    .detail .accountList .item{
        margin-right: 0px;
    }
}
</style>